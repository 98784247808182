import SubHeading from "../../../components/SubHeading/SubHeading";
import { colors } from "../../../config/colors/colors";
import Input from "../../../components/Input/Input";
import Button from "../../../components/Button/Button";

const TitlePermissionDetails = [
  {
    label: "Permission For",
    value: <Input type={"text"} />,
  },
  {
    label: "Permission From",
    value: <Input type={"text"} />,
  },
  {
    label: "Request Date",
    value: <Input type={"date"} />,
  },
  {
    label: "Status",
    value: <Input type={"text"} />,
  },
  {
    label: "Permission Read Date",
    value: <Input type={"date"} />,
  },
  {
    label: "Amount",
    value: <Input type={"number"} />,
  },
  {
    label: "Paid By",
    value: <Input type={"text"} />,
  },
  {
    label: "Contact Person",
    value: <Input type={"text"} />,
  },
  {
    label: "Address",
    value: <Input type={"text"} />,
  },
  {
    label: "Remarks",
    value: <Input type={"text"} />,
  },
];

const TitlePermissionUpdate = () => {
  return (
    <div>
      <SubHeading label="Title Permission Update" />
      <div className="bg-white p-4 shadow-lg">
        <div
          className={`${colors.primaryBackground} px-4 py-2 text-white font-semibold`}>
          Title Details
        </div>
        <div className="flex w-full border p-1 ">
          <div className="w-1/2">
            <div className="flex items-center">
              <div className="font-semibold w-1/4 ">Title Code</div>
              <div>: TM831B</div>
            </div>
            <div className="flex items-center my-1">
              <div className="font-semibold w-1/4">PI ISBN</div>
              <div>: 9788131787144</div>
            </div>
          </div>
          <div className="w-1/2 ">
            <div className="flex">
              <div className="font-semibold w-1/4">Working Title</div>
              <div>: Ulas - New Hindi Course Bk 6, 1/e</div>
            </div>
            <div className="flex">
              <div className="font-semibold w-1/4">Title TMSID</div>
              <div>: 014439</div>
            </div>
          </div>
        </div>

        <div
          className={`${colors.primaryBackground} px-4 py-2 text-white font-semibold`}>
          Title Permissions
        </div>
        <div className="bg-gray-200 mt-1 px-4 py-2 text-black font-semibold ">
          {"Permission Details"}
        </div>
        <div className="px-4 py-2 text-md ">
          {TitlePermissionDetails.map((item, index) => {
            return (
              <div className="flex items-center space-x-8 w-full my-1" key={index}>
                <div className="w-[280px] text-black font-semibold">
                  {item.label}
                </div>
                <div className=""> {item.value}</div>
              </div>
            );
          })}
        </div>
        <div className="flex items-center justify-center my-4">
          <div className="mr-4">
            <Button title={"Update"} color={"primary"} type={"button"} />
          </div>
          <Button title={"Cancel"} color={"danger"} type={"button"} />
        </div>
      </div>
    </div>
  );
};

export default TitlePermissionUpdate;

import React, { InputHTMLAttributes } from "react";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  value?: any;
  name?: string;
  type?: string;
  title?: string;
  errors?: string;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  width?: string;
  height?: string;
  clearText?: boolean;
  onClearText?: () => void;
}

const Input = (props: InputProps) => {
  const {
    onChange,
    value,
    name,
    type,
    errors,
    label,
    placeholder,
    disabled,
    width,
    height,
    clearText,
    onClearText,
    ...rest
  } = props;
  return (
    <div className="mt-2 mx-2 text-md">
      <div className="text-sm mb-1">
        <label className="firstName text-zinc-900" data-testid="label">
          {label}
        </label>
      </div>
      <div className="flex items-center border">
        {" "}
        <div>
          <input
            data-testid="input"
            {...rest}
            name={name}
            type={type}
            onChange={onChange}
            value={value}
            placeholder={placeholder}
            disabled={disabled}
            className={`${width ? width : "min-w-[300px]"} ${
              height ? height : "h-8"
            }  rounded-sm outline-none pl-2 text-sm`}
          />
        </div>
        {clearText && value !== "" ? (
          <div
            data-testid="oncleartext"
            className="w-8 h-8 flex items-center justify-center  cursor-pointer"
            onClick={onClearText}>
            <i className="fa-solid fa-xmark"></i>
          </div>
        ) : null}
        {errors ? (
          <div className="text-sm text-[red]" data-testid="error">
            {errors}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Input;
